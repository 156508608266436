<template>
  <div class="p-32">
    <b-row>
      <b-col cols="12" class="mb-32">
        <b-col cols="12">
          <h2>Listado de edificios</h2>
        </b-col>

        <div
          class="divider mx-16 border-black-40 hp-border-color-dark-80"
        ></div>
        <div class="col-12 mt-16">
          <datatable :items="items" :fields="fields">
            <template slot="filters">
              <b-col cols="3" class="my-3">
                <b-button
                  v-b-modal.modal-create-building
                  variant="primary"
                  class="btn-ghost"
                >
                  Añadir un nuevo edificio
                </b-button>
              </b-col>
            </template>
            <template slot="actions" slot-scope="{ item }">
              <b-row class="actions-icons">
                <!-- <span title="ver" @click="show(  item.uuid  )">  <i class="iconly-Light-Show"></i>  </span> -->
                <span
                  :id="'_show_button_' + item.uuid"
                  title="Ver detalles"
                  @click="show(item.uuid)"
                >
                  <i class="iconly-Light-Show mr-10" />
                </span>
                <span
                  :id="'_show_eliminar' + item.uuid"
                  title="eliminar"
                  @click="showDeleteConfirmation(item.uuid)"
                >
                  <i class="iconly-Light-Delete mr-10" />
                </span>
              </b-row>

              <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
                Ver
              </b-tooltip>

              <!-- <b-tooltip
                :target="'_show_editar' + item.uuid"
                triggers="hover"
              >
                Editar
              </b-tooltip> -->

              <b-tooltip
                :target="'_show_eliminar' + item.uuid"
                triggers="hover"
              >
                Eliminar
              </b-tooltip>
            </template>

            <template slot="pagination">
              <b-pagination
                align="end"
                :total-rows="totalRows"
                :per-page="totalRowsPerpage"
                @change="pageChanged"
              />
            </template>
          </datatable>
        </div>

        <!-- <BuildingList
          v-for="item in items"
          :key="item.id"
          :body="item"
        /> -->
      </b-col>
      <!-- Modal de confirmación de eliminación -->
      <b-modal
        id="modal-delete-confirmation"
        title="eliminar"
        cancel-variant="text"
        body-class="py-48"
        header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
        centered
        hide-footer
        modal-class="modal-delete-confirmation"
      >
        <template #modal-header="{ close }">
          <div
            class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
          >
            <h5 class="mb-0">Confirmar eliminación</h5>

            <b-button
              variant="text"
              @click="close()"
              class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
              style="min-height: 24px"
            >
              <i
                class="ri-close-line hp-text-color-dark-0 lh-1"
                style="font-size: 24px"
              ></i>
            </b-button>
          </div>

          <div class="divider my-0 p-0"></div>
        </template>

        <b-row>
          <b-col cols="12">
            <p class="text-center">
              ¿Estás seguro de que deseas eliminar este edificio?
            </p>
          </b-col>

          <b-col cols="6" class="pr-2">
            <b-button variant="danger" block @click="confirmDelete">
              Sí, eliminar
            </b-button>
          </b-col>

          <b-col cols="6" class="pl-2">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-delete-confirmation')"
            >
              Cancelar
            </b-button>
          </b-col>
        </b-row>
      </b-modal>

      <!-- Create Modal -->
      <b-modal
        id="modal-create-building"
        cancel-variant="text"
        body-class="py-48"
        header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
        centered
        hide-footer
        modal-class="hp-profile-modal-1"
      >
        <template #modal-header="{ close }">
          <div
            class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
          >
            <h5 class="mb-0">Añadir un nuevo edificio</h5>

            <b-button
              variant="text"
              class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
              style="min-height: 24px"
              @click="close()"
            >
              <i
                class="ri-close-line hp-text-color-dark-0 lh-1"
                style="font-size: 24px"
              />
            </b-button>
          </div>

          <div class="divider my-0 p-0" />
        </template>

        <form>
          <b-row>
            <b-col cols="12">
              <b-form-group label="Tipo de edificio">
                <b-form-select
                  v-model="buildingData.type"
                  :options="buildingtype"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Nombre">
                <b-form-input v-model="buildingData.name" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Comuna">
                <b-form-select
                  v-model="buildingData.city"
                  :options="locationtype"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Tipo de Documento">
                <b-form-select
                  v-model="buildingData.dni_type_uuid"
                  :options="DniType"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="
                buildingData.dni_type_uuid == 4 ||
                buildingData.dni_type_uuid == 3
              "
              cols="12"
            >
              <b-form-group label="RUT">
                <imask-input
                  v-imask="dniMask"
                  class="form-control"
                  :value="buildingData.dni"
                  :unmask="true"
                  placeholder="00.000.000-00"
                  @complete="onCompleteDni"
                />
              </b-form-group>
            </b-col>

            <b-col
              v-if="
                buildingData.dni_type_uuid != 4 &&
                buildingData.dni_type_uuid != 3
              "
              cols="12"
            >
              <b-form-group label="Documento">
                <b-form-input v-model="buildingData.dni" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Razón social">
                <b-form-input v-model="buildingData.razon_social" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Dirección">
                <b-form-input v-model="buildingData.address" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group label="Sobre nosotros">
                <b-form-input v-model="buildingData.about_us" type="text" />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
                Activo
              </span>

              <b-form-checkbox
                v-model="buildingData.is_active"
                switch
                size="lg"
                value="true"
              />
            </b-col>

            <b-col cols="6 pr-12">
              <b-button variant="primary" block @click="AddNewBuilding">
                Crear
              </b-button>
            </b-col>

            <b-col cols="6 pl-12">
              <b-button
                variant="none"
                block
                @click="$bvModal.hide('modal-create-building')"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        </form>
      </b-modal>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BCard,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption,
  BPagination,
} from "bootstrap-vue";

import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import Search from "@/layouts/components/content/action-button/BuildingsSearchList.vue";
import ActionButton from "@/layouts/components/content/action-button/BuildingsActionButton.vue";
import BuildingList from "@/layouts/components/building/list.vue";
import sweet from "@/logic/functions/sweetAlert";
import dni from "@/logic/functions/dni";
import validate_dni from "@/logic/functions/users/validate_dni";
import { IMaskComponent, IMaskDirective } from "vue-imask";
import datatable from "@/layouts/components/datatable/Datatable.vue";
import EventBus from "@/logic/functions/EventBus";
import building from "@/logic/functions/buildings";
import buildingType from "@/logic/functions/buildingsType";
import Locations from "@/logic/functions/Locations";
import users from "@/logic/functions/users";

export default {
  directives: {
    imask: IMaskDirective,
  },
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    "imask-input": IMaskComponent,
    BCard,
    BFormCheckbox,
    BPagination,
    BFormSelect,
    BFormSelectOption,
    Breadcrumb,
    Search,
    ActionButton,
    datatable,
    BuildingList,
  },
  data() {
    return {
      routeName: "",
      items: [],
      fields: [
        {
          key: "uuid",
          label: "Nombre",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        {
          key: "building_type_uuid",
          label: "Tipo de Edificio",
          formatter: (value, key, item) => item.building_type_uuid.name,
        },
        {
          key: "city_uuid",
          label: "Comuna",
          formatter: (value, key, item) => item.city_uuid.name,
        },
        { key: "actions", label: "Acciones" },
      ],
      dniIsValid: false,
      DniType: [],
      buildingtype: [],
      locationtype: [],
      totalRows: 0,
      paginationData: {},
      totalRowsPerpage: 5,
      currentPage: 0,
      dniMask: {
        mask: "00.000.000-{[*]}",
        lazy: false,
      },
      buildingData: {},
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  watch: {
    user_info() {
      this.data.dni_type_uuid = 3;
    },
  },
  mounted() {
    this.listDniType();
    this.ListBuildingType();
    this.ListLocations();
    this.InitialBuildingsList();
  },
  methods: {
    onCompleteDni(e) {
      const dni = e.detail._value.replace(/\./g, "");
      if (validate_dni.validaRut(dni)) {
        this.dniIsValid = true;
        this.buildingData.dni = e.detail._value;
        e.target.style.border = "none";
      } else {
        this.dniIsValid = false;
        e.target.style.border = "3px solid #FF0000";
      }
    },
    async show(id) {
      await users
        .select_building({
          params: {
            building_uuid: id,
            admin: true,
          },
        })
        .then((response) => {
          EventBus.$emit("BUILDING_TOKEN", id);
          localStorage.setItem("home_app_selectBuilding", id);
          this.$router.push(`/admin/building/${id}`);
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "El edificio no se pudo seleccionar");
        });
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    async deletes(uuid) {
      await building
        .delete(uuid)
        .then((response) => {
          sweet.ToastMsg("", "success", "Edificio eliminado");
          this.items = this.items.filter((e) => e.uuid !== uuid);
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "El edificio no eliminado");
        });
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;
      this.communications(page);
    },
    edit(uuid) {
      this.communicationCreate = {};
      this.communicationCreate = this.items.find((item) => item.uuid == uuid);
      this.$bvModal.show("modal-edit-communications-admin");
    },
    AddNewBuilding() {
      this.buildingData.dni_type_uuid = String(this.buildingData.dni_type_uuid);
      const createData = {
        building_type_uuid: this.buildingData.type.uuid,
        city_uuid: this.buildingData.city.uuid,
        name: this.buildingData.name,
        dni: this.buildingData.dni,
        dni_type_uuid: this.buildingData.dni_type_uuid,
        razon_social: this.buildingData.razon_social,
        address: this.buildingData.address,
        active: !!this.buildingData.is_active,
        about_us: this.buildingData.about_us,
      };

      building
        .create(createData)
        .then((response) => {
          this.buildingData.uuid = response.data.uuid;
          this.buildingData.type = this.buildingData.type.name;
          this.buildingData.city = this.buildingData.city.name;
          //   this.items.push(this.buildingData)
          this.InitialBuildingsList();
          this.buildingData = {};
          this.$bvModal.hide("modal-create-building");
        })

        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo crear el edificio");
        });
    },
    InitialBuildingsList() {
      // building.list()
      users
        .my_buildings({
          params: {
            admin: true,
          },
        })
        .then((response) => {
          this.items = [];
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;

            value.city = value.city_uuid.name;
            value.is_active = value.active;
            value.type = value.building_type_uuid.name;

            this.items.push(value);
          });
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo obtener los edificios");
        });
    },
    ListBuildingType() {
      buildingType
        .list()
        .then((response) => {
          const { data } = response.data;

          Object.entries(data).forEach((entry) => {
            const [key, value] = entry;
            this.buildingtype.push({
              value: {
                uuid: value.uuid,
                name: value.name,
              },
              text: value.name,
            });
          });
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo crear el edificio");
        });
    },
    ListLocations() {
      Locations.list()
        .then((response) => {
          const { data } = response.data;

          Object.entries(data).forEach((entry) => {
            const [key, value] = entry;
            this.locationtype.push({
              value: {
                uuid: value.uuid,
                name: value.name,
              },
              text: value.name,
            });
          });
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "no se pudo crear el edificio");
        });
    },
    listDniType() {
      dni
        .list()
        .then((response) => {
          Object.entries(response.data.data).forEach((entry) => {
            const [key, value] = entry;
            this.DniType.push({ value: value.uuid, text: value.name });
          });
        })
        .catch((error) => {});
    },
  },
};
</script>
