<template>
  <b-col class="hp-flex-none w-auto mt-32">
    <b-row>
      
      <b-col class="hp-flex-none w-auto pr-0">
        <b-button 
        variant="primary" 
        class="btn-ghost" 
        v-b-modal.modal-edit-contact>
          Create Buiding
        </b-button>

      </b-col>

    </b-row>
  </b-col>
</template>

<script>
import { BRow, BCol, BButton } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
};
</script>
