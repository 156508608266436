<template>
              <div :id="building.uuid" class="buildings-list">
                <b-col cols="12" class="building_options" >
                            <b-row class="actions-icons">
                                    <span title="eliminar" @click="deletes(  building.uuid  )"> <i class="iconly-Light-Delete mr-10"></i> </span>
                            </b-row>
                </b-col>
                <b-col cols="12" sm="6" xl="12" class="mb-32" @click="show(building.uuid)">
                    <b-card class="hp-card-1" :class="[this.active ? 'active-building' : 'inactive-building']" >
                        <b-row>
                            <b-col cols="12">
                                <b-row>
                                    <b-col cols="2">
                                        <div class="text-left">
                                            <img
                                                :src="require('@/assets/img/building/building.png')"
                                                alt="Building"
                                                style="height: 7rem;"
                                            />
                                        </div>
                                    </b-col>


                                    <b-col cols="10">
                                        <b-row class="text-left">
                                            <b-col cols="12" class="text-left">
                                                <h2>{{ building.name }} </h2>

                                                <span>
                                                <span class="mb-0 mt-12 mr-4 text-black-100 hp-text-color-dark-0 hp-p1-body font-weight-medium">Tipo:</span> {{ building.type }}
                                                </span>
                                                -
                                                <span>
                                                <span class="mb-0 mt-12 mr-4 text-black-100 hp-text-color-dark-0 hp-p1-body font-weight-medium">Ciudad:</span> {{ building.city }}
                                                </span>
                                                -
                                                <span>
                                                <span class="mb-0 mt-12 mr-4 text-black-100 hp-text-color-dark-0 hp-p1-body font-weight-medium">Dirección:</span> {{ building.address }}
                                                </span>
                                                -
                                                <span>
                                                <span class="mb-0 mt-12 mr-4 text-black-100 hp-text-color-dark-0 hp-p1-body font-weight-medium">RUT:</span> {{ building.dni }}
                                                </span>
                                                -
                                                <span>
                                                <span class="mb-0 mt-12 mr-4 text-black-100 hp-text-color-dark-0 hp-p1-body font-weight-medium">Razón social:</span> {{ building.razon_social }}
                                                </span>
                                            
                                            </b-col>
                                        </b-row>
                                    </b-col>
                                    
                                </b-row>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-col>
              </div>
</template>
  
  
<script>

import users from '@/logic/functions/users';
import buildings from '@/logic/functions/buildings';
import EventBus from '@/logic/functions/EventBus';
import sweet from '@/logic/functions/sweetAlert';

  import {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BCard
  } from "bootstrap-vue";
  
  export default {
    data() {
      return {
        building : this.body,
        active : this.body.is_active
      };
    },
    components: {
      BRow,
      BCol,
      BBadge,
      BButton,
      BDropdown,
      BDropdownItem,
      BModal,
      BForm,
      BFormGroup,
      BFormInput,
      BFormTextarea,
      BCard
    },
    props: {
        body: {
          type: Object,
        },
    },
    methods: {
      async show(id){
        await users.select_building({ params: {
          "building_uuid": id,
          "admin": true
        }}).then((response) => {
          EventBus.$emit('BUILDING_TOKEN', id);
          localStorage.setItem('home_app_selectBuilding',id);          
          this.$router.push('/admin/building/'+id);
        }).catch((error) => {
          sweet.ToastMsg('','error','El edificio no se pudo seleccionar');
        });
          
      },
     async deletes(uuid){
      await buildings.delete(uuid).then((response) => {
        document.getElementById(uuid).remove()
          sweet.ToastMsg('','success','Edificio eliminado');
        }).catch((error) => {
          sweet.ToastMsg('','error','El edificio no eliminado');
        });
      }
    },

    mounted(){
  
    },
 
  };
</script>
  
<style scoped>
  .active-building{
    border-color: #42b012;
  }

  .inactive-building{
    border-color: rgb(176 18 108);
  }
</style>