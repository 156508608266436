<template>
  <b-row>
    <b-col cols="12">
      <h2>Tipos</h2>
    </b-col>

    <div class="divider mx-16 border-black-40 hp-border-color-dark-80"></div>

    <div class="col-12 mt-16">
      <datatable :items="items" :fields="fields">
        <template slot="filters">
          <b-col cols="3" class="my-3">
            <b-button
              variant="primary"
              class="btn-ghost"
              v-b-modal.modal-add-communications-admin
            >
              Añadir nuevo tipo
            </b-button>
          </b-col>
        </template>

        <template slot-scope="{ item }" slot="actions">
          <b-row class="actions-icons">
            <!-- <span title="ver" @click="show(  item.uuid  )">  <i class="iconly-Light-Show"></i>  </span> -->
            <span
              title="editar"
              @click="edit(item.uuid)"
              :id="'_show_editar' + item.uuid"
            >
              <i class="iconly-Light-Edit mr-10"></i>
            </span>
            <span
              title="eliminar"
              @click="showDeleteConfirmation(item.uuid)"
              :id="'_show_eliminar' + item.uuid"
            >
              <i class="iconly-Light-Delete mr-10"></i>
            </span>
          </b-row>

          <b-tooltip :target="'_show_button_' + item.uuid" triggers="hover">
            Ver
          </b-tooltip>

          <b-tooltip :target="'_show_editar' + item.uuid" triggers="hover">
            Editar
          </b-tooltip>

          <b-tooltip :target="'_show_eliminar' + item.uuid" triggers="hover">
            Eliminar
          </b-tooltip>
        </template>

        <template slot="pagination">
          <b-pagination
            align="end"
            :total-rows="totalRows"
            :per-page="totalRowsPerpage"
            @change="pageChanged"
          ></b-pagination>
        </template>
      </datatable>
    </div>
    <!-- Modal de confirmación de eliminación -->
    <b-modal
      id="modal-delete-confirmation"
      title="eliminar"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-delete-confirmation"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Confirmar eliminación</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <b-row>
        <b-col cols="12">
          <p class="text-center">
            ¿Estás seguro de que deseas eliminar este tipo de edificio?
          </p>
        </b-col>

        <b-col cols="6" class="pr-2">
          <b-button variant="danger" block @click="confirmDelete">
            Sí, eliminar
          </b-button>
        </b-col>

        <b-col cols="6" class="pl-2">
          <b-button
            variant="none"
            block
            @click="$bvModal.hide('modal-delete-confirmation')"
          >
            Cancelar
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <!-- create communications -->
    <b-modal
      size="xl"
      id="modal-add-communications-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-add-communications-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Añadir nuevo tipo</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="communicationCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="communicationCreate.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitCommunication">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-add-communications-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- edit communications -->
    <b-modal
      size="xl"
      id="modal-edit-communications-admin"
      cancel-variant="text"
      body-class="py-50"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      content-class="modal-edit-communications-admin"
      modal-class="hp-profile-modal-1"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Editar nuevo tipo</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form inline>
        <b-row>
          <b-col cols="12">
            <b-form-group label="Nombre">
              <b-form-input
                v-model="communicationCreate.name"
                type="text"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <span class="hp-caption text-black-80 hp-text-color-dark-30 pr-8">
              Activo
            </span>

            <b-form-checkbox
              switch
              size="lg"
              v-model="communicationCreate.active"
              value="true"
            ></b-form-checkbox>
          </b-col>

          <b-col cols="6 pr-12">
            <b-button variant="primary" block @click="submitCommunicationEdit">
              Añadir
            </b-button>
          </b-col>

          <b-col cols="6 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-edit-communications-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal>

    <!-- View communications detail -->
    <!-- <b-modal
      id="modal-detail-communications-admin"
      cancel-variant="text"
      body-class="py-48"
      header-class="align-items-center flex-wrap pt-16 pb-0 px-0"
      centered
      hide-footer
      modal-class="modal-detail-communications-admin"
    >
      <template #modal-header="{ close }">
        <div
          class="w-100 d-flex align-items-center justify-content-between mb-16 px-24"
        >
          <h5 class="mb-0">Ver detalle del comunicado</h5>

          <b-button
            variant="text"
            @click="close()"
            class="btn-close bg-transparent p-0 d-flex align-items-center justify-content-center"
            style="min-height: 24px"
          >
            <i
              class="ri-close-line hp-text-color-dark-0 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>

        <div class="divider my-0 p-0"></div>
      </template>

      <form>
        <b-row>

          <b-col cols="12">
            <b-form-group label="Título">
              <b-form-input v-model="communicationCreate.title" type="text" :disabled="true" ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12" class="mt-8">
            <b-form-group label="Contenido">
              <b-form-input v-model="communicationCreate.content" type="text" :disabled="true"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col cols="12 pl-12">
            <b-button
              variant="none"
              block
              @click="$bvModal.hide('modal-detail-communications-admin')"
            >
              Cerrar
            </b-button>
          </b-col>
        </b-row>
      </form>
    </b-modal> -->
  </b-row>
</template>

<script>
import {
  BCard,
  BTable,
  BBadge,
  BButton,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BInputGroup,
  BInputGroupAppend,
  BFormSelect,
  BFormFile,
  BFormSelectOption,
  BFormDatepicker,
  BCalendar,
  BPagination,
} from "bootstrap-vue";

import datatable from "@/layouts/components/datatable/Datatable.vue";
import sweet from "@/logic/functions/sweetAlert";
import buildingsType from "@/logic/functions/buildingsType";

export default {
  components: {
    BCard,
    BTable,
    BBadge,
    BButton,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BInputGroup,
    BInputGroupAppend,
    BFormSelect, // this
    BFormSelectOption,
    BFormDatepicker,
    BCalendar, //this
    BFormFile,
    BPagination,
    datatable,
  },
  data() {
    return {
      fields: [
        {
          key: "uuid",
          label: "Tipo de edificio",
          formatter: (value, key, item) => {
            return item.name;
          },
        },
        { key: "active", label: "Activo" },
        { key: "actions", label: "Acciones" },
      ],
      Actions: [],
      items: [],
      loadNewUsers: [],
      codeText: "",
      codeActive: false,
      codeActiveClass: false,
      flags: {
        formUpload: false,
        fileUpload: false,
      },
      createSales: {},
      communicationCreate: {
        active: true,
      },
      paginationData: {},
      totalRows: 0,
      totalRowsPerpage: 5,
      currentPage: 0,
    };
  },
  methods: {
    show(uuid) {
      this.communicationCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });

      this.$bvModal.show("modal-detail-communications-admin");
    },
    edit(uuid) {
      this.communicationCreate = {};
      this.communicationCreate = this.items.find((item) => {
        return item.uuid == uuid;
      });
      this.$bvModal.show("modal-edit-communications-admin");
    },
    confirmDelete() {
      if (this.itemToDelete) {
        this.deletes(this.itemToDelete);
        this.$bvModal.hide("modal-delete-confirmation");
      }
    },
    showDeleteConfirmation(uuid) {
      this.itemToDelete = uuid;
      this.$bvModal.show("modal-delete-confirmation");
    },
    async deletes(uuid) {
      await buildingsType
        .delete(uuid)
        .then((response) => {
          this.communications();
          sweet.ToastMsg("", "success", "Comunicacion eliminada");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "Comunicacion no eliminada");
        });
    },
    uploadFile(event) {
      this.documentCreate.file = event.target.files[0];
    },
    async communications(page = 0) {
      await buildingsType
        .lists({
          params: {
            limit: 5,
            page: page,
          },
        })
        .then((response) => {
          this.items = response.data.data;
        })
        .catch((error) => {});
    },
    async submitCommunication() {
      await buildingsType
        .create({
          name: this.communicationCreate.name,
          active: this.communicationCreate.active,
        })
        .then((response) => {
          this.items.push(response.data);
          sweet.ToastMsg("", "success", "Tipo de edificio creado");
          this.$bvModal.hide("modal-add-communications-admin");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "Tipo de edificio no creado");
        });
    },
    async submitCommunicationEdit() {
      await buildingsType
        .update(
          this.communicationCreate.uuid,
          {
            name: this.communicationCreate.name,
            content: this.communicationCreate.content,
          },
          this.communicationCreate.uuid
        )
        .then((response) => {
          sweet.ToastMsg("", "success", "Tipo de edificio editado");
          this.$bvModal.hide("modal-edit-communications-admin");
        })
        .catch((error) => {
          sweet.ToastMsg("", "error", "Tipo de edificio no editado");
        });
    },
    async pageChanged(page) {
      page = page == 1 ? 0 : page - 1;

      this.communications(page);
    },
  },
  beforeMount() {},
  mounted() {
    this.communications();
  },
};
</script>
