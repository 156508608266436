<template>
  <div cols="12" class="mb-32">
      <b-row>
        <b-col cols="12">
            <b-form-input placeholder="Escribe para iniciar la búsqueda" type="text"></b-form-input>
        </b-col>

        <!-- <b-col cols="4">
            <b-form-input placeholder="Search by code" type="text"></b-form-input>
        </b-col>

        <b-col cols="4">
          <b-form-select
            v-model="selected"
            :options="options"
            class="mb-3"
            value-field="item"
            text-field="building"
            place
          ></b-form-select>
        </b-col> -->
      </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BModal,
  BForm,
  BFormGroup,
  BFormInput, //this
  BFormTextarea,
  BFormSelect, // this
  BFormSelectOption //this
} from "bootstrap-vue";

export default {
  data() {
    return {
      selected: null,
      options: [
        { item: null, building: "Please select some building" },
        { item: "A", building: "Option A" },
        { item: "B", building: "Option B" },
        { item: "D", building: "Option C", notEnabled: true },
        { item: { d: 1 }, building: "Option D" },
      ],
    };
  },
  components: {
    BRow,
    BCol,
    BButton,
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect, 
    BFormSelectOption
  },
};
</script>
