<template>
  <b-row>
    <b-col cols="12" class="mb-32">
      <b-row align-h="between" class="mt-n32">
        <b-col class="hp-flex-none w-auto mt-32">
          <breadcrumb />
        </b-col>
      </b-row>
    </b-col>

    <b-col cols="12" class="mb-32">
      <b-row
        class="hp-profile-mobile-menu-btn bg-black-0 hp-bg-color-dark-100 rounded py-12 px-8 px-sm-12 mb-16 mx-0"
      >
        <div class="d-inline-block">
          <b-button
            variant="none"
            class="border-0 btn-icon-only"
            v-b-toggle.sidebar-profile
          >
            <i
              class="ri-menu-fill hp-text-color-black-80 hp-text-color-dark-30 lh-1"
              style="font-size: 24px"
            ></i>
          </b-button>
        </div>
      </b-row>

      <b-row
        class="bg-black-0 hp-bg-color-dark-100 rounded pr-16 pr-sm-32 mx-0"
      >
        <sidebar />
        <b-col class="pl-16 pl-sm-32 p--24 py-sm-32 overflow-hidden">
          <!-- <List />
          <Edificio /> -->
          <List  v-if="$route.name == 'admin-configuration-building-type' " />
          <Edificio  v-if="$route.name == 'admin-configuration-building' " />
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BBadge,
  BButton,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";

import Breadcrumb from "@/layouts/components/content/breadcrumb/Breadcrumb.vue";
import ActionButton from "@/layouts/components/content/action-button/ActionButton.vue";
import Sidebar from "./Sidebar.vue";
import Edificio from "../buildings/list/index.vue"
import List from "./List.vue";

import users from "@/logic/functions/users";

export default {
  data() {
    return {
      routeName: "",
      building: {},
    };
  },
  components: {
    BRow,
    BCol,
    BBadge,
    BButton,
    BDropdown,
    BDropdownItem,
    Breadcrumb,
    ActionButton,
    Sidebar,
    List,
    Edificio,
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    async getSales() {},
  },
  beforeMount() {},
  mounted() {
    this.getSales();
  },
};
</script>
