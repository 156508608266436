//import axios from 'axios';
import http from '../../axios/axios_conf';
import api from '../../routers/routers';

class buildingsType {
 
  list(){
    return http.get( api.building_type.get.endpoint );
  }

  lists(params){
    return http.get( api.building_type.get.endpoint, params );
  }

  create(buildingsTypeData){
    return http.post( api.building_type.create.endpoint, buildingsTypeData );
  }
  
  update(uuid,data){
    return http.put( api.building_type.update.endpoint+uuid, data );
  }
  
  delete(uuid){
    return http.delete( api.building_type.delete.endpoint+uuid );
  }

}

export default new buildingsType();